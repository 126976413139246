import { gql, QueryHookOptions } from "@apollo/client"

import { MeQuery, MeQueryVariables, useMeQuery } from "lib/graphql"

const _ = gql`
  fragment Me on User {
    id
    firstName
    lastName
    fullName
    pronounsMulti
    pronounsOther
    region
    residence
    address
    postcode
    avatarUrl
    avatar
    customSlug
    role
    email
    profileIncomplete
    hasPendingFeedback
    hasOrderedPromoPackage
    interests
    createdAt
    howDidYouHearMulti
  }

  query Me {
    me {
      ...Me
    }
  }
`

export function useMe(options?: QueryHookOptions<MeQuery, MeQueryVariables>) {
  const res = useMeQuery(options)
  return { me: res.data?.me, ...res }
}
